dialog:not([open]) {
	animation-name: none;
}

dialog {
	overflow-y: auto;
	border: 1px solid #ccc;
	border-radius: 2px;
	box-shadow: 0 6px 60px rgba(0,0,0,.8);
	padding: 0;
	max-width: 85vw;
	max-height: 85vh;
}

dialog[open] + .backdrop, dialog::backdrop {
	animation: fadeIn 200ms ease-in;
}

dialog > *:not(header):not(footer) {
	margin: 0.8em;
}
