@import url("./utility.css");

.clearfix::after {
	clear: both;
	content: '';
	display: block;
}

.shadow {
	box-shadow: 0 2px 10px var(--shadow-color, rgba(0,0,0,.2));
}

.shadow-dark {
	box-shadow: 0 2px 10px var(--shadow-dark-color, rgba(0,0,0,.6));
}

.icon, .icon * {
	color: inherit;
	max-width: 100%;
	max-height: 100%;
	width: var(--icon-size, 1em);
	height: var(--icon-size, 1em);
	vertical-align: middle;
}

.round {
	border-radius: 50%;
}

.card {
	border: var(--card-border, 1px solid #ccc);
	border-radius: var(--card-radius, 2px);
	background: var(--card-bg, white);
	padding: 0.7em;
}
