@charset "UTF-8";
@import url("./vars.css");
@import url("../core-css/viewport.css");
@import url("../normalize/normalize.css");
@import url("../core-css/rem.css");
@import url("../core-css/element.css");
@import url("../core-css/class-rules.css");
@import url("../core-css/animations.css");
@import url("../core-css/fonts.css");
@import url("../animate.css/animate.css");
@import url("./dialog.css");
@import url("./icons.css");
@import url("./contact.css");
@import url("./header.css");
@import url("./footer.css");

html, body {
	min-height: 100vh;
}

body {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
}

main.card {
	width: 80%;
	padding: 2em;
	margin-left: 10%
}

[data-scene="lake"] {
	background-image: var(--lake-background-h);
}

[data-scene="river"] {
	background-image: var(--river-background-h);
}

dialog {
	border: none;
}

summary[role="button"] {
	display: list-item;
}

#events-calendar {
	grid-template-columns: repeat(auto-fill, minmax(320px, 500px));
	grid-gap: 0.8em;
	justify-content: center;
}

@media (orientation: landscape and min-width: 1025), (orientation: portrait and max-height: 768px) {
	[data-scene="lake"] {
		background-image: var(--lake-background-f);
	}

	[data-scene="river"] {
		background-image: var(--river-background-f);
	}
}

@media (orientation: landscape and max-width: 640px), (orientation: portrait and max-height: 480px) {
	[data-scene="lake"] {
		background-image: var(--lake-background-l);
	}

	[data-scene="river"] {
		background-image: var(--river-background-l);
	}
}

@media (orientation: landscape and max-width: 320px), (orientation: portrait and max-height: 240px) {
	[data-scene="lake"] {
		background-image: var(--lake-background-m);
	}

	[data-scene="river"] {
		background-image: var(--river-background-m);
	}
}

@media (orientation: portrait and max-width: 160px), (orientation: portrait and max-height: 120px) {
	[data-scene="lake"] {
		background-image: var(--lake-background-s);
	}

	[data-scene="river"] {
		background-image: var(--river-background-s);
	}
}
